/**
 * implementations of the states of an export within the aHub
 */
export enum ClientQuotaTypeAHubEnum {
    EXPORTS = "EXPORTS",
    EXPORTS_SIZE_BYTES = "EXPORTS_SIZE_BYTES",
    DATA_SETS = "DATA_SETS",
    DATA_SIZE_BYTES = "DATA_SIZE_BYTES",
    DIST_GROUPS = "DIST_GROUPS",
    WORK_GROUPS = "WORK_GROUPS",
    DISTRIBUTIONS = "DISTRIBUTIONS",
    DG_USERS = "DG_USERS",
    WG_USERS = "WG_USERS",
    LIBRARY_ASSET_COUNT = "LIBRARY_ASSET_COUNT",
    LIBRARY_ASSET_BYTES = "LIBRARY_ASSET_BYTES",
    LIBRARY_PRODUCTS = "LIBRARY_PRODUCTS",
}
