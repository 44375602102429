import { Validators, FormControl } from '@angular/forms';

/**
 * Directives.
 */
import { numericalStartValidator } from 'modules/common/directives/validators/numerical-start-validator.directive';
import { alphaNumericValidator } from 'modules/common/directives/validators/alpha-numeric-validator.directive';
import { lowerCaseStartValidator } from 'modules/common/directives/validators/lower-case-start-validator.directive';

/**
 * This function will create and return a form control to be used on a JSON tag value.
 * 
 * @param defaultValue          The default value to pass into the form.
 */
export const jsonFormControlCreate = (defaultValue): FormControl => {

    // Create and return the form control with the correct validators already applied.
    return new FormControl(defaultValue, [Validators.required, Validators.maxLength(45), alphaNumericValidator(), numericalStartValidator(), lowerCaseStartValidator()]);
}