/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

/**
 * Utilities
 */
import { SelectorUtil } from 'store/selector.util';
import { ListUtil } from 'store/list.util';

/**
 * Redcuer
 */
import { ComponentTreeState } from 'reducers/component/component-tree.reducer';

/**
 * Selectors
 */
import { componentState } from 'selector/component/component.selector';


/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */
import { TreeNodeVO } from 'modules/common/components/tree/tree.component';

/**
 * Root selector to get the library classification view state from the store state
 */
const componentTreeState = (state): ComponentTreeState => componentState(state).tree;

/**
 * --------------------------------------------------
 * Selectors
 * -------------------------------------------------- */

/**
 * A selector to access the tree data
 */
export const componentTreeData = (state): TreeNodeVO[] => componentTreeState(state).treeData;
