import * as crypto from 'crypto-js';


export class ColourUtils {

    private static SATURATION: number = 0.4;
    private static VALUE: number = 0.9;


    public static stringToColour(value: string): string {
        let hash = crypto.MD5(value).toString();
        let firstWord = crypto.enc.Base64.parse(hash).words[0];
        let decimalHash = parseFloat(((Math.abs(firstWord) / 1000000000) % 1).toString()).toFixed(4)

        return ColourUtils.hueToColour(parseFloat(decimalHash));
    }


    /**
     * Create a colour with the Hue we want passed in.
     * 
     * @param value             The hue to convert.
     */
    public static hueToColour(value: number): string {

        // Create the hashed colour.
        return ColourUtils.HSVtoRGB(value, ColourUtils.SATURATION, ColourUtils.VALUE);
    }


    /**
     * Get the hue value from the colour passed in.
     * 
     * @param colour            The colour to covert.
     */
    public static colourToHue(colour: string): number {

        // Stop any errors from empty colours.
        if (!colour)
            return 0;

        // Remove the first character.
        let re = /#/gi;
        colour = colour.replace(re, "");

        // Get the RGB values from the colour string.
        let r = parseInt(colour.substr(0, 2), 16);
        let g = parseInt(colour.substr(2, 2), 16);
        let b = parseInt(colour.substr(4, 2), 16);

        // Now get the HSV colour.
        let hsv: number[] = ColourUtils.rgbToHsv(r, g, b);

        // Get the hue.
        let hue = (hsv && hsv.length > 0) ? hsv[0] : 0;

        // Then return the hue.
        return parseFloat(hue.toPrecision(4));
    }


    /**
     * Convert a HSV colour to RGB.
     * 
     * @param h             The hue to convert.
     * @param s             The saturation to convert.
     * @param v             The value to convert.
     */
    private static HSVtoRGB(h, s, v): string {

        let r, g, b, i, f, p, q, t;
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0: r = v, g = t, b = p; break;
            case 1: r = q, g = v, b = p; break;
            case 2: r = p, g = v, b = t; break;
            case 3: r = p, g = q, b = v; break;
            case 4: r = t, g = p, b = v; break;
            case 5: r = v, g = p, b = q; break;
        }

        return '#' + Math.round(r * 255).toString(16) + Math.round(g * 255).toString(16) + Math.round(b * 255).toString(16);
    }


    /**
     * Convert the RGB back into the HSV array.
     * 
     * @param r         The red part of the colour to convert.
     * @param g         The green part of the colour to convert.
     * @param b         The blue part of the colour to convert.
     */
    private static rgbToHsv(r, g, b) {

        r /= 255, g /= 255, b /= 255;
        let max = Math.max(r, g, b);
        let min = Math.min(r, g, b);
        let h;
        let s = ColourUtils.SATURATION;
        let v = ColourUtils.VALUE;

        let d = max - min;

        if (max == min) {
            h = 0; // achromatic
        } else {
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, v];
    }
}