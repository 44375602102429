export var SPCHAR_SPACE: string = "0";


let special_value_map_newValue = {
    "0": "&nbsp;"
}

let special_value_map_oldValue = {
    "&nbsp;": " "
}

/**
 * Get the special char id
 *
 * @param specialCharId     Get the id of the character that we want
 */
export function specialCharGet(specialCharId: string) {
    return special_value_map_newValue[specialCharId];
};


/**
 * Get the special char id
 *
 * @param specialCharId     Get the id of the character that we want
 */
export function specialCharGetOriginal(value: string) {
    return special_value_map_oldValue[value];
};